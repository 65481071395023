$secondary-yellow: #fff5c1;

$system-error-200: #f5ae9d;

$system-success-100: #ccf4de;
$primary-lime-100: #e7fdf3;
$shades-stone-200: #f3f2f0;

$colors-list: (
  neutral: (
    1: #ffffff,
    2: #fafafa,
    3: #f0f0f0,
    4: #e6e6e6,
    5: #ededed,
    6: #bdbdbd,
    7: #9e9e9e,
    8: #6d6d6d,
    9: #4f4f4f,
    10: #171717,
    11: #f2f2f2,
    12: #e0e0e0,
    13: #171717,
    14: #d9d9d9,
    15: #f3f2ef,
  ),
  accent: (
    1: #0e5eb9,
    2: #214a8e,
    3: #08386d,
    4: #e0e3fa,
  ),
  blue: (
    1: #eff5fb,
    2: #e6eff9,
    3: #1e8ce6,
    4: #1676c5,
    5: #1260a0,
    6: #d0f0fd,
    7: #cfdfff,
    8: #1676c5,
    9: #e0ebff,
    10: #dbf6fa,
    11: #f2f4f7,
    700: #13678a,
  ),
  red: (
    1: #fceae9,
    2: #fae0de,
    3: #e24236,
    4: #df2d20,
    5: #c9291d,
    6: #d34022,
    7: #fcece9,
    8: #e53811,
    50: #fef3f2,
    700: #b42318,
  ),
  green: (
    1: #e9f3ed,
    2: #dbebe1,
    3: #249e57,
    4: #1e8549,
    5: #166437,
    6: #e6fbe0,
    7: #278b62,
    50: #ecfdf3,
    100: #e7f9f0,
    700: #027a48,
    800: #469928,
    900: #228000,
  ),
  orange: (
    1: #f7efe6,
    2: #ddbe96,
    3: #cea36b,
    4: #ab6100,
    5: #784400,
    6: #bd7e00,
    7: #e59900,
    8: #c9671d,
    600: #c9671d,
    700: #f07400,
  ),
  yellow: (
    1: #ffeab6,
    2: #fff0cc,
    3: #fbf5da,
    100: #fffae0,
    800: #615514,
  ),
  pink: (
    1: #e6dcf9,
    2: #fbe4f4,
    3: #fbe4e0,
  ),
  gray: (
    1: #828282,
    2: #333f53,
    100: #f2f4f7,
    600: #475467,
    700: #344054,
    900: #101828,
  ),
  black: (
    1: #282828,
    2: #000,
    3: #282624,
  ),
  purple: (
    50: #f7f2fd,
    600: #9062cb,
    700: #734ba6,
  ),
  lime: (
    100: #e7fdf3,
    200: #c6f9e5,
    800: #308060,
    900: #104832,
  ),
  error: (
    50: #fce8e4,
    100: #fad5cc,
    200: #f5ae9d,
    300: #ef866f,
  ),
  success: (
    50: #ecfdf3,
    700: #027a48,
  ),
  warning: (
    50: #fff6ee,
    600: #dc8f47,
    700: #b97535,
  ),
  stone: (
    100: #f9f8f8,
    500: #e1deda,
    600: #b5b1ad,
    700: #7c7972,
    800: #35312d,
  ),
  brand: (
    100: #ffe5cd,
  ),
);

@each $baseColor, $variants in $colors-list {
  @each $variantName, $value in $variants {
    .bg-#{$baseColor}-#{$variantName} {
      background-color: #{$value} !important;
    }
    .border-color-#{$baseColor}-#{$variantName} {
      border-color: #{$value} !important;
    }
    .svg-path-fill-#{$baseColor}-#{$variantName} {
      path {
        fill: #{$value};
      }
    }
    .#{$baseColor}-#{$variantName} {
      color: #{$value} !important;
    }
  }
}

.black-3 {
  color: #171717;
}

.primary-lime-700 {
  color: #46a680;
}

.bg-primary-lime-700 {
  background: #46a680;
}

.color-disabled {
  color: #e7e5e1 !important;
}

.bg-color-disabled {
  background: #e7e5e1;
}

.bg-white-2 {
  background-color: #f9f6f6;
}

.bg-white-3 {
  background-color: #f9f8f8;
}

.color-neutral-9 {
  color: #4f4f4f;
}

.color-hint-text {
  color: #7c7972;
}

.sky-700 {
  color: #3a91b4 !important;
}

.bg-sky-700 {
  background: #3a91b4;
}

.shades-stone-50 {
  color: #fcfcfb !important;
}

.neutral-light {
  color: #828282 !important;
}

.bg-orange {
  background-color: orange;
}

.bg-white {
  background-color: white;
}

.bg-transparent {
  background-color: transparent;
}

.bg-secondary-yellow {
  background-color: $secondary-yellow !important;
}

.bg-system-error-200 {
  background-color: $system-error-200 !important;
}

.bg-system-success-100 {
  background-color: $system-success-100 !important;
}

.bg-primary-lime-100 {
  background-color: $primary-lime-100;
}

.bg-shades-stone-200 {
  background-color: $shades-stone-200;
}

.bg-icons-brand {
  background: #fff5ec;
}
